import React, { useContext } from "react"
import { AppContext } from "../context/context"
import { FaPhoneAlt } from "react-icons/fa"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import * as styles from "./form-stub.module.scss"

const FormStub = ({ content, phoneNumber, currentPage }) => {
  const { size, setName, setEmailAddress } = useContext(AppContext)
  const { fields, heading, icon, subheading, button_text } = content.elements

  const setFields = e => {
    const { name, value } = e.target
    switch (name) {
      case "full_name":
        return setName(value)
      case "email":
        return setEmailAddress(value)
      default:
        break
    }
  }

  const getTablet = () => {
    if (size >= 1024) {
      return (
        <a href={`tel: ${phoneNumber}`} className={styles.callButton}>
          {phoneNumber}
          <div className={styles.phone}>
            <FaPhoneAlt />
          </div>
        </a>
      )
    } else {
      return ""
    }
  }
  const getLaptop = () => {
    if (size >= 1366) {
      return (
        <div className={styles.fieldContainer}>
          {fields.value.map(({ elements, id }) => {
            const { label, form_field, field_type } = elements
            return (
              <div key={id} className={styles.field}>
                <label htmlFor={form_field.value[0].codename}>
                  {label.value}
                </label>
                <input
                  type={field_type.value[0].codename}
                  name={form_field.value[0].codename}
                  placeholder={
                    field_type.value[0].codename === "email"
                      ? "email@example.com"
                      : "John Smith"
                  }
                  onBlur={setFields}
                />
              </div>
            )
          })}
        </div>
      )
    } else {
      return ""
    }
  }
  return (
    <section className={styles.bannerCta}>
      <div className={`container ${styles.ctaContainer}`}>
        <div className={styles.ctaHook}>
          <img src={icon.value[0].url} alt={icon.value[0].description} />
          <div className={styles.freeQuote}>
            <h3 className="uppercase">{heading.value}</h3>
            <p>{subheading.value}</p>
          </div>
        </div>
        <div className={styles.ctaFormContainer}>
          {getLaptop()}
          <AnchorLink
            to={`${currentPage}#form-banner`}
            className="accent-button"
          >
            {button_text.value}
          </AnchorLink>
        </div>
        {getTablet()}
      </div>
    </section>
  )
}

export default FormStub
