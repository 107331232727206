import React from "react"
import { graphql } from "gatsby"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import Layout from "../components/layout"
import SEO from "../components/seo/seo"
import SimpleHero from "../components/simple-hero/SimpleHero"
import ContentBlocks from "../components/content-blocks/ContentBlocks"
import GlobalFooter from "../components/global-footer/GlobalFooter"
import FormStub from "../components/form-stub/FormStub"

const PestPage = ({ data, pageContext }) => {
  const [pest] = data.allKontentItemPest.nodes
  const {
    name,
    banner,
    seo_metadata__meta_title,
    seo_metadata__meta_description,
    content_blocks,
    global_footer,
    cta_form_stub,
  } = pest.elements
  const {
    breadcrumb: { crumbs },
  } = pageContext
  const { pestroutes_reviews, corporate_head_office, reviews, review_images } =
    data.kontentItemSettings.elements
  const { tracking_number } = corporate_head_office.value[0].elements
  return (
    <Layout location={crumbs.location}>
      <SEO
        title={seo_metadata__meta_title.value}
        description={seo_metadata__meta_description.value}
      />
      <SimpleHero banner={banner.value[0]} />
      {cta_form_stub?.value.length > 0 && (
        <FormStub
          content={cta_form_stub.value[0]}
          phoneNumber={tracking_number.value}
          currentPage={pageContext.breadcrumb.location}
        />
      )}
      <Breadcrumb
        crumbs={crumbs}
        crumbSeparator=" > "
        crumbLabel={name.value}
      />
      <ContentBlocks content={content_blocks} meta={pageContext} />
      {global_footer.value.length > 0 ? (
        <GlobalFooter
          content={global_footer}
          phone={tracking_number}
          pestroutesReviews={pestroutes_reviews}
          reviewImages={review_images}
          staticReviews={reviews}
          meta={pageContext}
        />
      ) : (
        ""
      )}
    </Layout>
  )
}

export const getPest = graphql`
  query getPest($slug: String!) {
    allKontentItemPest(
      filter: { elements: { slug: { value: { eq: $slug } } } }
    ) {
      nodes {
        elements {
          name {
            value
          }
          banner {
            value {
              ... on kontent_item_simple_hero_banner {
                id
                elements {
                  heading {
                    value
                  }
                  subheading {
                    value
                  }
                  background_image {
                    value {
                      description
                      fluid(maxWidth: 5000, quality: 75) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                  background_color {
                    value {
                      name
                    }
                  }
                }
              }
            }
          }
          cta_form_stub {
            value {
              ... on kontent_item_form_stub_banner {
                id
                elements {
                  icon {
                    value {
                      url
                      description
                    }
                  }
                  heading {
                    value
                  }
                  button_text {
                    value
                  }
                  subheading {
                    value
                  }
                  fields {
                    value {
                      ... on kontent_item_form_field {
                        id
                        elements {
                          form_field {
                            value {
                              codename
                            }
                          }
                          field_type {
                            value {
                              codename
                            }
                          }
                          label {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          short_description {
            value
          }
          content_blocks {
            value {
              ... on kontent_item_faq_group {
                id
                __typename
                elements {
                  faq_list {
                    value {
                      ... on kontent_item_faq {
                        id
                        elements {
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          question {
                            value
                          }
                          answer {
                            value
                            links {
                              link_id
                              url_slug
                            }
                          }
                        }
                      }
                    }
                  }
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                }
              }
              ... on kontent_item_image {
                id
                __typename
                elements {
                  single_image {
                    value {
                      description
                      fluid(maxWidth: 768, quality: 75) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on kontent_item_google_rating_banner {
                id
                __typename
                elements {
                  google_url {
                    value
                  }
                }
              }
              ... on kontent_item_content_block {
                id
                __typename
                elements {
                  heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  content {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      fluid(maxWidth: 768, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                      description
                    }
                  }
                  buttons {
                    value {
                      ... on kontent_item_anchor_button {
                        id
                        elements {
                          anchor_reference {
                            value {
                              name
                            }
                          }
                          button_text {
                            value
                          }
                        }
                      }
                      ... on kontent_item_button {
                        id
                        elements {
                          button_text {
                            value
                          }
                          linked_page {
                            value {
                              ... on kontent_item_page {
                                id
                                __typename
                                elements {
                                  slug {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  image_gallery {
                    value {
                      ... on kontent_item_image_gallery {
                        id
                        elements {
                          gallery {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                  html {
                    value
                  }
                }
              }
              ... on kontent_item_multi_content_block {
                id
                __typename
                elements {
                  multi_block_heading {
                    value
                  }
                  sub_heading {
                    value
                  }
                  alt_background_color {
                    value {
                      codename
                    }
                  }
                  content_block {
                    value {
                      ... on kontent_item_content_block {
                        id
                        elements {
                          heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                          content {
                            value
                            links {
                              link_id
                              url_slug
                            }
                          }
                          image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          alt_background_color {
                            value {
                              codename
                            }
                          }
                          buttons {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  anchor_reference {
                                    value {
                                      codename
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        __typename
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          image_gallery {
                            value {
                              ... on kontent_item_image_gallery {
                                id
                                elements {
                                  gallery {
                                    value {
                                      ... on kontent_item_image {
                                        id
                                        elements {
                                          single_image {
                                            value {
                                              fluid(
                                                maxWidth: 768
                                                quality: 65
                                              ) {
                                                ...KontentAssetFluid_withWebp_noBase64
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          html {
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
              ... on kontent_item_faq {
                id
                __typename
                elements {
                  question {
                    value
                  }
                  answer {
                    value
                    links {
                      link_id
                      url_slug
                    }
                  }
                  image {
                    value {
                      description
                      fluid(maxWidth: 768, quality: 65) {
                        ...KontentAssetFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
              ... on kontent_item_image_gallery {
                id
                __typename
                elements {
                  gallery {
                    value {
                      ... on kontent_item_image {
                        id
                        elements {
                          single_image {
                            value {
                              description
                              fluid(maxWidth: 768, quality: 65) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          seo_metadata__canonical_link {
            value
          }
          seo_metadata__meta_description {
            value
          }
          seo_metadata__meta_title {
            value
          }
          global_footer {
            value {
              ... on kontent_item_global_footer {
                id
                elements {
                  global_footer_items {
                    value {
                      ... on kontent_item_review_position {
                        id
                        system {
                          type
                        }
                      }
                      ... on kontent_item_global_footer_blog_banner {
                        id
                        elements {
                          blog_banner_heading {
                            value
                          }
                          sub_heading {
                            value
                          }
                        }
                      }
                      ... on kontent_item_cta_banner {
                        id
                        elements {
                          cta_heading {
                            value
                          }
                          description {
                            value
                          }
                          background_image {
                            value {
                              fluid(maxWidth: 5000, quality: 40) {
                                ...KontentAssetFluid_withWebp_noBase64
                              }
                            }
                          }
                          button {
                            value {
                              ... on kontent_item_anchor_button {
                                id
                                elements {
                                  anchor_reference {
                                    value {
                                      name
                                    }
                                  }
                                  button_text {
                                    value
                                  }
                                }
                              }
                              ... on kontent_item_button {
                                id
                                elements {
                                  button_text {
                                    value
                                  }
                                  linked_page {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        __typename
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_form_banner {
                        id
                        elements {
                          form_heading {
                            value
                          }
                          form_sub_heading {
                            value
                          }
                          form {
                            value {
                              ... on kontent_item_form {
                                id
                                elements {
                                  submission_message_body {
                                    value
                                  }
                                  submission_message_heading {
                                    value
                                  }
                                  submit_button_text {
                                    value
                                  }
                                  fields {
                                    value {
                                      ... on kontent_item_form_field {
                                        id
                                        elements {
                                          field_type {
                                            value {
                                              codename
                                            }
                                          }
                                          hidden_field_value {
                                            value
                                          }
                                          label {
                                            value
                                          }
                                          options {
                                            value
                                          }
                                          required {
                                            value {
                                              codename
                                            }
                                          }
                                          form_field {
                                            value {
                                              codename
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      ... on kontent_item_logo_slider {
                        id
                        elements {
                          logo_slider_heading {
                            value
                          }
                          logo_slider {
                            value {
                              ... on kontent_item_image {
                                id
                                elements {
                                  single_image {
                                    value {
                                      description
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64

                                        src
                                      }
                                    }
                                  }
                                }
                              }
                              ... on kontent_item_image_link {
                                id
                                elements {
                                  image {
                                    value {
                                      fluid(maxWidth: 768, quality: 65) {
                                        ...KontentAssetFluid_withWebp_noBase64
                                      }
                                      description
                                    }
                                  }
                                  internal_link {
                                    value {
                                      ... on kontent_item_page {
                                        id
                                        __typename
                                        elements {
                                          slug {
                                            value
                                          }
                                        }
                                      }
                                    }
                                  }
                                  url {
                                    value
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    kontentItemSettings {
      elements {
        pestroutes_reviews {
          value {
            codename
          }
        }
        corporate_head_office {
          value {
            ... on kontent_item_office {
              id
              elements {
                tracking_number {
                  value
                }
                phone_number {
                  value
                }
                name {
                  value
                }
                city {
                  value
                }
                state {
                  value {
                    codename
                    name
                  }
                }
                zip {
                  value
                }
                address_line_1 {
                  value
                }
                address_line_2 {
                  value
                }
              }
            }
          }
        }
        reviews {
          value {
            ... on kontent_item_review {
              id
              elements {
                heading {
                  value
                }
                location {
                  value {
                    ... on kontent_item_service_area {
                      id
                      elements {
                        label {
                          value
                        }
                        service_area_name {
                          value
                        }
                        slug {
                          value
                        }
                      }
                    }
                  }
                }
                date {
                  value(formatString: "DD MMMM YYYY")
                }
                rating {
                  value {
                    codename
                  }
                }
                review {
                  value
                }
                reviewer {
                  value
                }
                review_source_link {
                  value
                }
              }
            }
          }
        }
        review_images {
          value {
            ... on kontent_item_review_image {
              id
              elements {
                review_image {
                  value {
                    fluid(maxWidth: 768) {
                      ...KontentAssetFluid_withWebp_noBase64
                    }
                    description
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default PestPage
